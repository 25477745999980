<script>
import { onMounted, onUnmounted, ref, reactive } from 'vue'
import { http } from '@/http'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { uuid, regFenToYuan } from '@/utils'

export default {
  setup(props) {
    let trustInfo = reactive({})
    http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getTaxCommissionRecord')
      .then(res => {
        if(res.result) {
          for(let item in res.result) {
            trustInfo[item] = res.result[item]
          }
          // trustInfo.realName = res.result.realName
          // trustInfo.cardNum = res.result.cardNum
          // trustInfo.bankNum = res.result.bankNum
          // trustInfo.bindBankName = res.result.bindBankName
          // trustInfo.bindBranchName = res.result.bindBranchName
          // trustInfo.taxCommissionStatus = res.result.taxCommissionStatus
          // trustInfo.reason = res.result.reason
          // trustInfo.cardBack = res.result.cardBack
          // trustInfo.cardFront = res.result.cardFront
        }
      })
    return {
      trustInfo
    }
  }

}
</script>
<template>
  <div class="page">
    <div class="box">
      <h4>上传身份证照</h4>
      <div class="tbl">
        <div class="cell" style="display:table-cell;width:40%;position: relative">
          <div style="width:93%; display: inline-block; position:relative">
            <div class="card-bg card-front-bg" ></div>
            <div class="image-cover">  
              <img v-if="trustInfo.cardFront" :src="trustInfo.cardFront" /> 
            </div>
          </div>
        </div>
        <div class="cell" style="display:table-cell; width:40%;position: relative; text-align:right">
          <div style="width:93%;display: inline-block;position:relative">
            <div class="card-bg card-back-bg"></div>
            <div class="image-cover" >    
              <img v-if="trustInfo.cardBack" :src="trustInfo.cardBack" /> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <h4>基本信息</h4>
      <div class="result-item">
        <ul>
          <li><span>真实姓名：</span>{{trustInfo.realName}}</li>
          <li><span>身份证号：</span>{{trustInfo.cardNum}}</li>
          <li><span>银行卡号：</span>{{trustInfo.bankNum}}</li>
          <li><span>银行名称：</span>{{trustInfo.bindBankName}}</li>
          <li><span>支行名称：</span>{{trustInfo.bindBranchName}}</li>
          <li>{{trustInfo.other}}</li>
        </ul>
      </div>
    </div>
    <div style="margin: .4rem;">
      <template v-if="trustInfo.taxCommissionStatus === 'REJECT'">
        <van-button block type="primary" @click="$router.push('/trust')">修改信息</van-button>
        <p style="color: #EC0909;  text-align: center;font-size:0.24rem; margin-top:.3rem">驳回原因：{{trustInfo.reason}}</p>
      </template>
      <template v-if="trustInfo.taxCommissionStatus === 'NEW'">
        <van-button block type="primary">委托书申请中</van-button>
        <p style="color: #EC0909; text-align: center; font-size:0.24rem; margin-top:.3rem">{{trustInfo.reason}}</p>
      </template>

    </div>
  </div>
</template>
<style scoped>
      h4 {font-size: .3rem; font-weight: 700; margin: .3rem 0}
    .box { overflow: hidden;font-size: .28rem; padding: 0 .4rem; margin-bottom: .3rem; background-color: #fff;}
    .box li { display:table; width:100%; margin-top:.5rem }
    .box li span { display: table-cell;  vertical-align: middle; width:2.3rem }
    .box li:last-child { margin-bottom:.5rem }
  .tbl { table-layout: fixed; margin-bottom: .4rem;display:table; width: 100%}
  .tbl .cell { vertical-align:middle;font-size: 0.24rem;display: table-cell }
/*  .tbl .cell:last-child { text-align: right}
*/  .image-cover { position:absolute; top: 0; left:0; height:2rem;width:100% }
  .image-cover img {width: 100%; height:100% }
  .card-bg{display: inline-block;height:2rem;width:100%}
  .card-front-bg { background-size: 100% 100%; background-image:url('~@/assets/images/card-front.png') }
  .card-back-bg { background-size: 100% 100%;background-image:url('~@/assets/images/card-back.png') }
  .btn { float:right; border-radius:5px; font-size: .24rem; padding: .13rem .46rem; color: #00ABEB; background:#fff }
  
</style>